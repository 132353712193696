<template>
  <div>
    
    <content-sheet>
      <div class="large-width-padding">
        <back-navigation :currentName="collection.title[+($i18n.locale !== 'sv')] || collection.title[0]" :backName="$t('video.image-header')" />
        <h1 class="text--primary" style="text-align: left">{{ collection.title[+($i18n.locale !== 'sv')] || collection.title[0] }}</h1>
        <p v-if="collection.description" style="white-space: pre-wrap; text-align: left" >{{ collection.description[+($i18n.locale !== 'sv')] || collection.description[0] }}</p>
        <div class="video-container" style="margin-top: 30px">
          <!-- <div class="video"> -->
            <youtube :video-id="currentVidID"></youtube>
          <!-- </div> -->
        </div>
        <h3 style="margin: 20px 0 10px 0; text-align: left">{{ $t('video.videos') }}</h3>
        <div class="video-list">
          <div class="divider" style="background-color: #00000020"></div>
          <div v-for="(video, index) in collection.videos" :key="index" @click="selectVideo(index)">
            <div class="video-list-item text-hoverable" :class="{'active': index === currentVideo}">
              <div style="display: flex; align-items: center">
                <img class="video-thumbnail" :src="thumbnail(index)" alt="thumb">
                <h3 class="text--primary" style="margin-right: 10px; margin-left: 20px">{{ video.title[+($i18n.locale !== 'sv')] || video.title[0] }}</h3>
              </div>
              <PlayFillIcon v-if="index === currentVideo" style="margin-right: 10px"/>
              <PlayIcon v-else style="margin-right: 10px"/>
            </div>
            <div class="divider" style="background-color: #00000020"></div>
          </div>
        </div>
      </div>
    </content-sheet>
  </div>
</template>

<script>
import ContentSheet from '../components/ContentSheet.vue'
import PlayIcon from '../assets/icons/play.svg'
import PlayFillIcon from '../assets/icons/play_fill.svg'
import BackNavigation from '../components/BackNavigation.vue'

import { getIdFromURL } from 'vue-youtube-embed'
// import { Youtube } from 'vue-youtube'

export default {
  data() {
    return {
      currentVideo: 0,
    }
  }, 
  metaInfo() {
    return {
      title: this.collection.title[+(this.$i18n.locale !== 'sv')]
    }
  },
  methods: {
    selectVideo(index) {
      this.currentVideo = index
    },
    thumbnail(index) {
      return 'https://img.youtube.com/vi/' + getIdFromURL(this.collection.videos[index].url) + '/hqdefault.jpg'
    }
  },
  components: {
    ContentSheet,
    PlayIcon,
    PlayFillIcon,
    BackNavigation
    // Youtube,
  },
  computed: {
    currentVidID() {
      // return "2z6u1Ddu7xA"
      return getIdFromURL(this.collection.videos[this.currentVideo].url)
    },
    collection() {
      for(const collection of this.$store.state.videoCollections) {
        if(collection.slug === this.$route.params.slug) return collection
      }

      return {}
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style>
.video-container {
  width: 100%;
  background-color: white;
}

iframe {
  width: 100%;
  height: 100%;
  min-height: min(40vw, 562px);
  aspect-ratio: 16/9;
}

iframe div {
  width: 100%;
  height: 100%;
}

.video-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  /* padding: 10px 10px; */
  border-radius: 10px;
  border: 2px solid transparent;
}

/* .video-list-item.active {
  border: 2px solid #e5b409;
} */

.video-thumbnail {
  max-width: none;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  object-fit: cover;
}

</style>